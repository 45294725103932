$slider-line-height: 28px;
$slider-border-radius: 4px;

.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;

  &.slider-horizontal {
    width: 100%;
    height: $slider-line-height;
    .slider-track {
      height: $slider-line-height;
      width: 100%;
      margin-top: -$slider-line-height/2;
      top:  50%;
      left: 0;
    }
    .slider-selection, .slider-track-low, .slider-track-high {
      height: 100%;
      top: 0;
      bottom: 0;
    }
    .slider-tick,
    .slider-handle {
      margin-left: -10px;
      margin-top: -4px;
    }
    .slider-tick-label-container {
      white-space: nowrap;

      .slider-tick-label {
        margin-top: $slider-line-height * 1.2;
        display: inline-block;
        text-align: center;
      }
    }
  }

  &.slider-disabled {
    .slider-handle {
      @include slider_background-image(#dfdfdf, #bebebe, #f7f7f7);
      border: none;
      cursor: not-allowed;
    }
    .slider-track,
    {
      @include slider_background-image(#e5e5e5, #e9e9e9, #f7f7f7);
      cursor: not-allowed;
      .slider-selection {
        @include slider_background-image(#e5e5e5, #e9e9e9, #f7f7f7);
      }
    }
  }
}

.slider-handle {
  box-shadow: 0.6px 0.6px 3px #333333, 0px 0px 0.6px #404040;
  border: 0.2px solid #2fa6f4;
  height: 34px;
  width: 19px;
  border-radius: 2px;
  background: #edf0ee;
  cursor: pointer;
}

input {
  display: none;
}
.tooltip-inner {
  white-space: nowrap;
  max-width: none;
}
.tooltip {
  font-size: 18px;

  .tooltip-arrow {
    border-top-color: #333;
  }

  .tooltip-inner {
    background: #333;
  }

  &.top {
    margin-top: -46px;
  }
}

.hide {
  display: none;
}


.slider-track {
  position: absolute;
  cursor: pointer;
  @include slider_background-image(#f5f5f5, #f9f9f9, #f7f7f7);
  @include slider_box-shadow(inset 0 1px 2px rgba(0,0,0,0.1));
  // @include slider_border-radius($slider-border-radius);
}

.slider-selection {
  position: absolute;
  @include slider_background-image(#f9f9f9, #f5f5f5, #f7f7f7);
 @include slider_box-shadow(inset 0 -1px 0 rgba(0,0,0,0.15));
 @include slider_box-sizing(border-box);
 // @include slider_border-radius($slider-border-radius);
}
.slider-selection.tick-slider-selection {
  @include slider_background-image(#89cdef, #81bfde, #f7f7f7);
}

.slider-track-low, .slider-track-high {
  position: absolute;
  background: #ddd;
}

.slider-handle {
  position: absolute;
  // width:  $slider-line-height;
  // height: $slider-line-height;
  // background-color: #337ab7;
  // @include slider_background-image(#149bdf, #0480be, #0e90d2);
  // @include slider_box-shadow(inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05));
  // border: 0px solid transparent;
}

.slider-tick {
  position: absolute;
  width: $slider-line-height;
  height: $slider-line-height;
  @include slider_background-image(#f9f9f9, #f5f5f5, #f7f7f7);
  @include slider_box-shadow(inset 0 -1px 0 rgba(0,0,0,0.15));
  @include slider_box-sizing(border-box);
  filter: none;
  opacity: 0.8;
  border: 0px solid transparent;

  &.in-selection {
    @include slider_background-image(#89cdef, #81bfde, #f7f7f7);
    opacity: 1;
  }
}

.slider-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  // background: #3071a9;
  border-radius: 1.3px;
  border: 0px solid #000000;
}

.slider-selection {
  background: #367ebd;
}

.sliders {
  label {
    font-weight: bold;
    font-size: 16px;
  }

  .form-group {
    margin-bottom: 20px;
  }
}
