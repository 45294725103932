.listing {
  h5 {
    background: #eee;
    font-size: 16px;
    // font-weight: bold;
  }
}


.area {
  text-align: right;
  span {
    color: red;
  }

  .area-fits {
    color: green;
  }
}

.floor-info {
  hr {
    border-top-color: #ddd;
    margin: 5px 0;
  }
}

.room-list {
  font-size: 12px;
  margin-bottom: 5px;

  span:after {
    content: ',';

  }

  span:last-child:after {
    content: '';
  }
}

.btn-xs .glyphicon {
  top: 2px;
}

.listing .table {
  tfoot td {
    font-weight: bold;
  }

  td {
    width: 25%;
    vertical-align: middle;
    border-bottom: 1px solid #aaa;

    &:first-child {
      width: 50%;
    }
  }
}

.bootstrap-switch {
  z-index: 10;
  margin-bottom: 10px;
}

.bootstrap-switch-container span {
  white-space: nowrap;
}
