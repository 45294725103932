@page {
  size: landscape;
  margin: 10mm 10mm;
}

body {
  // height: 210mm;
  // width: 297mm;
}

.brand,
.navbar,
.legende,
.plan-legende,
.show-admintitle,
.show-title,
#menu {
  display: none !important;
}

.title {
  font-size: 10pt;
  height: 9mm;
  margin: 10px 0;
  text-align: center;
}

.slideout-menu {
  display: none !important;
}

.modal,
.print-button,
.modal-backdrop {
  display: none !important;
}

#panel {
  // height: 190mm;
  // width: 277mm;

  svg {
    -webkit-print-color-adjust: exact;
    width: auto;
    display: inline;
    // border: 1px solid #999;
  }
}

.map-container {
  height: 170mm;
  width: 277mm;
}

[class*="col-"],
#panel {
  margin: 0;
  padding: 0;
}


td .row {
 margin: 0;
}

a[href]:after {
  content: "";
}
