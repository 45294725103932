$icon-font-path: '../../node_modules/bootstrap-sass/assets/fonts/bootstrap/';
$fa-font-path: "../../node_modules/@bower_components/font-awesome/fonts";
@import "variables";


@import "node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../node_modules/@bower_components/seiyria-bootstrap-slider/src/sass/_mixins.scss";
@import "../../node_modules/bootstrap-select/sass/bootstrap-select";
@import "../../node_modules/@bower_components/bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css";

@import "../../node_modules/@bower_components/font-awesome/scss/font-awesome.scss";

@import "slideout";
@import "slider";
@import "search";


td,
th {
  &::selection {
    background: transparent;
  }
}

body {
  width: 100%;
  height: 100%;
}

.container-fluid {
  background: #fff;
  z-index: 1;
}

.brand {
  font-size: 20px;

  @media (min-width: $screen-md-min) {
    font-size: 29px;
  }
}

.header {
  // overflow: hidden;
  // background: rgba(255, 255, 255, 0.8);
  position: relative;
  // width: 100%;
  // z-index: 1;
  // padding-bottom: 4px;
  // border-bottom: 1px solid #fff;
  @media (min-width: $screen-md-min) {
    &.collapse {
      display: block;
    }
  }
}

.show-admintitle {
  float: right;
}

.show-title {
  position: absolute;
  z-index: 2;
  right: 0;
  font-size: 15px;
  padding: 2px 6px;
  @media (min-width: $screen-md-min) {
    display: none;

  }
}

.title {
  font-size: 12pt;
  margin: 0 0 5px;

  @media (min-width: $screen-md-min) {
    font-size: 24px;
    margin: 25px 0 5px;
    text-align: right;
  }
}

.map-container {
  // height:0;
  // padding-top:63.63%; /* 350px/550px */
  position: relative;

  svg {
    &.loading {
      background: url('../images/ring.gif') no-repeat center top;
    }

    &.display {
      display: block;
      width: 100%;
    }
  }

  // svg hack
  .SlideGroup g path:last-child {
    stroke-width: 10;
  }
}

.loading-ring {
  background: url("../images/ring-alt.gif") no-repeat center center;
  display: inline-block;
  width: 15px;
  height: 15px;
  background-size: contain;
  position: relative;
  top: 2px;
  left: -1px;
}

.loading-button {
  @extend .loading-ring;
  background-image: url("../images/load.svg");
  height: 18px;
  width: 18px;
}

$legende-border: #666;

.plan-legende {
  bottom: -100%;
  color: $legende-border;
  font-size: 12px;
  // position: absolute;
  right: 0;
  text-align: center;

  .legende-label {
    display: none;
  }

  @media (min-width: $screen-md-min) {
    font-size: 13px;
    text-align: right;
    bottom: -50%;

    .legende-label {
      display: inline;
    }

    li:first-child {
      border-left: 1px solid lighten($legende-border, 40%);
      border-right: 1px solid lighten($legende-border, 40%);
    }
    li:last-child {
      border-right: 1px solid lighten($legende-border, 40%);
    }
  }

  span, strong {
    vertical-align: -1px;

    &.label {
      vertical-align: 0;
    }
  }

  li {
    display: block;
    float: left;
    border-top: 1px solid lighten($legende-border, 40%);
    border-bottom: 1px solid lighten($legende-border, 40%);
    padding-bottom: 2px;
    padding-top: 2px;

    @media (max-width: $screen-sm-max) {
      border: 0;
    }
  }


  ul {
    float: right;
    margin: 0;
  }
}


@media print {
  @import "print";
}
